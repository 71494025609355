import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../data/PaginationPublication.js";
import Publication from "../../data/Publication.js";
import { Checkbox } from 'antd'
import { Slider } from '@mui/material';
import DOMPurify from "dompurify";


const Publications = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [value, setValue] = React.useState([1992, (new Date().getFullYear())])
    const [isLast, setIstLast] = useState(false)
    const publicationPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastPublication = currentPage * publicationPerPage;
    const indexOfFirstPublication = indexOfLastPublication - publicationPerPage;
    const currentPublication = filteredData.slice(indexOfFirstPublication, indexOfLastPublication);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const mockTipos = ['Journal article', 'Conference abstract', 'Conference paper', 'Book', 'Book chapter'];
    const [others, setOthers] = useState([])

    useEffect(() => {
        try {
            axios.get(window.config.DEV_LINK + "/output/ui/" + window.config.id).then((res) => {
                for (let index = 0; index < res.data.payload.length; index++) {
                    const element = res.data.payload[index]
                    element.artigo = element.artigo.replace(/<i>(.*?)<\/i>/g, '<i>$1</i>');
                    element.artigo = DOMPurify.sanitize(element.artigo);
                    if (mockTipos.indexOf(element.tipo_descr_en) === -1) {
                        others.push(element)
                    }
                }
                setData(res.data.payload)
                setSelectedFilters(mockTipos)



            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
        }
    }, []);

   



    const handleCheckboxChange = values => {
        setSelectedFilters(values);
        setCurrentPage(1)
    };

    useEffect(() => {
        if (selectedFilters.length === 0 && !loading) {
            setIstLast(true);
        } else {
            setIstLast(false);
        }
    }, [selectedFilters, loading]);


    const filterData = () => {
        if (data) {
            let filteredData = data;

            if (selectedFilters.length) {
                if (selectedFilters.includes('Other')) {
                    let pseudo = filteredData.filter(item => {
                        if (item.tipo_descr_en) {
                            return selectedFilters.includes(item.tipo_descr_en);
                        }
                        return false;
                    })
                    if (others.length) {
                        filteredData = pseudo.concat(others)
                    }
                }
                else {
                    filteredData = filteredData.filter(item => {
                        if (item.tipo_descr_en) {
                            return selectedFilters.includes(item.tipo_descr_en);
                        }
                        return false;
                    });
                }
            }
            filteredData = filteredData.filter(item => item.ano >= value[0] && item.ano <= value[1]);

            return filteredData;
        }
    };

    useEffect(() => {
        const result = filterData();
        setFilteredData(result);
    }, [selectedFilters, value]);

    const handleChange = (event, newValue) => {
        filterData()
        setValue(newValue);
        setCurrentPage(1)
        return false
    }

    const marks = [
        {
            value: 1992,
            label: 1992,
            style: {
                color: "black",
            },
        },
        {
            value: new Date().getFullYear(),
            label: new Date().getFullYear(),
            style: {
                color: "black",
            },
        },
    ];

    const options = mockTipos.map(option => {
        const count = data.filter(publication => publication.tipo_descr_en === option).length;
    
        return {
          label: `${option} (${count})`,
          value: option,
        };
      });
    
    

    return (
        <>
            <div>
                {options.length > 0 && (
                    <section className={`publications p-120 ${window.location.pathname === "/publications" ? "publications-page" : 3}`}>
                        {loading &&
                            <div className="preloader">
                                <div className="main-circle">
                                    <div className="green-circle">
                                        <div className="brown-circle">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="card">
                                        <div className="card-header-mine">
                                            <h5 className="card-tittle-mine">Filters</h5>
                                        </div>
                                        <div className="filter-tittle card-subtitle mb-2 text-muted">Types</div>

                                        <div className="card-body-two">
                                            <Checkbox.Group options={options} defaultValue={mockTipos} onChange={handleCheckboxChange} style={{ display: "flex", flexDirection: "column", marginLeft: "8px" }}>
                                                {options.map((option, index) => (
                                                    <div className="form-check" key={index}>
                                                        <input className="form-check-input" type="checkbox" value={option.value} id={`checkbox-${index}`} />
                                                        <label className="form-check-label" htmlFor={`checkbox-${index}`}>{option.label}</label>
                                                    </div>
                                                ))}
                                            </Checkbox.Group>
                                        </div>
                                        <hr></hr>
                                        <div className="filter-tittle card-subtitle mb-2 text-muted">Year</div>

                                        <div className="card-body-two" style={{ textAlign: 'center' }}>
                                            <Slider
                                                getAriaLabel={() => "Year range"}
                                                value={value}
                                                onChange={handleChange}
                                                valueLabelDisplay="auto"
                                                min={1992}
                                                max={(new Date().getFullYear())}
                                                marks={marks}
                                            />

                                        </div>
                                    </div>

                                </div>
                                {!isLast &&
                                    <div className="col-lg-10">
                                        <Publication publication={currentPublication} loading={loading} />
                                    </div>
                                }
                                {isLast &&
                                    <div className="col-lg-10">
                                        <h5 style={{ textAlign: 'center', color: 'dimgray' }}>Please select at least one filter type</h5>
                                    </div>
                                }
                            </div>
                        </div>

                        {!isLast &&
                            <div className="row">
                                <div className="col-lg-12">
                                    <Pagination count={20} currentPage={currentPage} publicationPerPage={publicationPerPage} totalPublication={filteredData.length} paginate={paginate} />
                                </div>
                            </div>
                        }

                    </section>
                )}

            </div>

        </>

    );
}

export default Publications;
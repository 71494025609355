import axios from "axios";
import { useEffect, useState } from "react";

export function useConfigs() {
    const [configs, setConfigs] = useState(null);
 
    useEffect(() => {
  
      try {
          axios.get(window.config.DEV_LINK + "/configs").then((response) => {
            setConfigs(response.data.payload);
          });
      } catch (error) {
          console.error(error);
      }
    }, []);

    return configs

}
import React, { memo } from "react";
import { Link } from "react-router-dom";
let sauce;

export const PostsScholarship = ({ posts }) => {
  return (
    <div className="col-lg-12">
      <div className="row justify-content-center">
        {posts.map((post) => {
          if (
            post.foto_path === null ||
            post.foto_path === "" ||
            post.foto_path === undefined
          ) {
            sauce = "https://adit.ipvc.pt/backend/backend/fotos/adit/test.svg";
          } else {
            //When bolseiros have foto change here
            sauce =
              "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/investigador/" +
              post.foto_path;
          }

          return (
            <div
              className="col-sm-6 col-xl-3 col-lg-4"
              key={post.INVESTIGADOR_ID}
            >
              <div className="item" key={post.INVESTIGADOR_ID}>
                <div
                  className="image"
                  style={{ display: "grid", justifyContent: "center" }}
                >
                  <img src={sauce} className="img-fluid" alt="Team Member" />
                </div>

                <div className="eachTeam" style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "24px" }}>
                    {post.nome_website === undefined ? (
                      <b>{post.Nome}</b>
                    ) : (
                      <b>{post.nome_website} </b>
                    )}
                  </span>

                  <p style={{ fontSize: "18px", color: "Black" }}>
                    {post.tipo_contrato ? post.tipo_contrato : post.EMAIL}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PostsScholarship;

import React, { useState, useEffect } from "react";
import axios from "axios";
import SingleProjectInfo from "./SingleProject";
import projectExample from "./149.json";
import { withRouter } from "react-router-dom";

function ProjectDetailsInner() {
  const [proj, setProj] = useState([]);
  const [team, setTeam] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [loading, setLoading] = useState(true);

  function decodeHtmlEntities(encodedText) {
    const entities = {
      "&lt;": "<",
      "&gt;": ">",
      "&#039;": "'",
      // Add more entities if needed
    };
    return encodedText.replace(
      /(&lt;|&gt;|&#039;)/g,
      (match) => entities[match]
    );
  }

  useEffect(() => {
    let url = window.location.pathname;
    let split = url.split("/");
    let directId = parseInt(split[2]);
    try {
      axios
        .get("https://adit.ipvc.pt/modulosipvc_producao/rest-api-slimv3/api/proxy/" + directId)
        .then((res) => {
          setProj(res.data.data[0]);
        })
        .finally(() => {
          setLoading(false);
          setloaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="preloader">
          <div className="main-circle">
            <div className="green-circle">
              <div className="brown-circle"></div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- start team details area --> */}
      <section className="team-detail pt-120 mt-120">
        <div className="container">
          <div className="row">
            {loaded && (
              <div className="col-lg-4">
                <SingleProjectInfo projeto={proj} />
              </div>
            )}
            {proj && (
              <div className="col-lg-8">
                <div
                  className="team-inner right-part"
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                >
                  {proj.summary && (
                    <div className="single-item">
                      <div className="item-title">
                        <h4>About</h4>
                      </div>
                      <p className="item justify">{proj.summary}</p>
                    </div>
                  )}
                  <div className="single-item mt-30">
                    <div className="item-title">
                      <h4>Team</h4>
                    </div>
                    {proj.team &&
                      proj.team.map((item, index) => {
                        let webSiteName = item.name;
                        let fullName = item.name;
                        if (item.name.includes(" - ")) {
                          [webSiteName, fullName] = item.name.split(" - ");
                        }
                        return (
                          <div key={index} className="project-team-name">
                            <p>
                              {fullName} - <b>{item.role}</b>
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(ProjectDetailsInner);

// colorReducer.js
const initialState = {
  color: '#13ADB6',
};

const colorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COLOR':
      return {
        ...state,
        color: action.payload,
      };
    default:
      return state;
  }
};

export default colorReducer;

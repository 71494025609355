import React, { useState, useEffect } from "react";
import axios from "axios";
import Posts from "../../data/Posts.js";
import PostsScholarship from "../../data/PostsScholarship.js";
import _ from "lodash";
import Button from "react-bootstrap/Button";

const TeamExecutiveBoard = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cargos, setCargos] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [selectedCargo, setSelectedCargo] = useState("");
  const [counts, setCounts] = useState([]);
  const [bolseirosData, setBolseirosData] = useState([]);
  const [labSupportData, setLabSupportData] = useState([]);
  const [isBolseiro, setIsBolseiro] = useState();
  let savedState = {};

  useEffect(() => {
    savedState = localStorage.getItem("teamExecutiveBoardState");
    if (savedState) {
      const { posts: savedPosts, selectedCargo: savedSelectedCargo } =
        JSON.parse(savedState);
      setPosts(savedPosts);
      setSelectedCargo(savedSelectedCargo);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "teamExecutiveBoardState",
      JSON.stringify({ posts, selectedCargo })
    );
  }, [posts, selectedCargo]);

  useEffect(() => {
    const fetchPostsAndLabSupport = async () => {
      try {
        // Fetch both posts and lab support data in parallel
        const [postsResponse, labSupportResponse] = await Promise.all([
          axios.get(
            window.config.DEV_LINK + "/investigador/getByUi/" + window.config.id
          ),
          axios.get(
            window.config.DEV_LINK + "/laboratorios/sup/" + window.config.id
          ),
        ]);

        const postsData = postsResponse.data.payload;
        const labSupportData = labSupportResponse.data.payload;

        setLabSupportData(labSupportResponse.data.payload);

        // Combine postsData and labSupportData
        const combinedData = [...postsData, ...labSupportData];

        // Set the combined data to allPosts state
        setAllPosts(combinedData);

        const integrados = _.filter(
          combinedData,
          (item) => item.TIPO_MEMBRO_ID === "3" || item.TIPO_MEMBRO_ID === "1"
        );
        const colaboradores = _.filter(
          combinedData,
          (item) => item.TIPO_MEMBRO_ID === "2" || item.TIPO_MEMBRO_ID === "17"
        );
        const phd = _.filter(
          combinedData,
          (item) => item.TIPO_MEMBRO_ID === "16" || item.TIPO_MEMBRO_ID === "17"
        );

        if (!savedState) {
          setPosts(integrados);
        }

        const updatedCounts = {
          1: integrados.length,
          2: colaboradores.length,
          6: bolseirosData.length,
          16: phd.length,
          99: labSupportData.length, // Count for lab support data
        };

        setCounts(updatedCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPostsAndLabSupport();
  }, [cargos, bolseirosData]);

  useEffect(() => {
    getCargos();
  }, []);

  useEffect(() => {
    const fetchBolseiros = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://onv3.ipvc.pt/key/obterBolseirosUI?ui=" +
            window.config.bolseirosUiId,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "api-key":
                "vP00UVadsafca43SFAWfcfaGpFtIfnr1PRdPndXhfyKlroWOYwfyc5Fsdj1v",
            },
          }
        );
        const result = await response.json();
        setBolseirosData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBolseiros();
  }, []);

  const getCargos = async () => {
    try {
      await axios
        .get(window.config.DEV_LINK + "/investigador/getTipo")
        .then((response) => {
          let cargos = _.filter(
            response.data.payload,
            (cargo) => !_.includes(["3", "4", "5"], cargo.ID)
          );

          // Add the new object to the cargos list
          cargos.push({ ID: "99", descr_en: "Laboratory Support" });

          // Update the state
          setCargos(cargos);

          if (!savedState) {
            setSelectedCargo(response.data.payload[0].descr_en);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  function handlePosts(e) {
    let typeCargo = e.target.value;
    setSelectedCargo(e.target.value);
    //para os botoes nao normais

    if (typeCargo === "Integrated") {
      setIsBolseiro(false);
      let filtPosts = allPosts.filter((fPosts) => {
        return fPosts.descr_en === typeCargo || fPosts.descr_en === "Direction";
      });
      setPosts(filtPosts);
    }
    if (typeCargo === "Colaborators") {
      setIsBolseiro(false);
      let filtPosts = allPosts.filter((fPosts) => {
        return (
          fPosts.descr_en === typeCargo ||
          fPosts.descr_en === "PhD Student / Colaborator"
        );
      });
      setPosts(filtPosts);
    }
    if (typeCargo === "Scholarship Holders") {
      setIsBolseiro(true);
      setPosts(bolseirosData);
    }
    if (typeCargo === "PhD student") {
      setIsBolseiro(false);
      let filtPosts = allPosts.filter((fPosts) => {
        return (
          fPosts.descr_en === typeCargo ||
          fPosts.descr_en === "PhD Student / Colaborator"
        );
      });
      setPosts(filtPosts);
    }
    if (typeCargo === "Laboratory Support") {
      setIsBolseiro(true);
      setPosts(labSupportData);
    }
  }

  return (
    <div>
      {/* <!-- start team area --> */}
      <section
        className={`team p-120 ${
          window.location.pathname === "/team-executive-board" ? "team-page" : 3
        }`}
      >
        {loading === true && (
          <div className="preloader">
            <div className="main-circle">
              <div className="green-circle">
                <div className="brown-circle"></div>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="row">
            <div
              className="col col-lg-12 col-sm-12"
              style={{ textAlign: "center" }}
            >
              {cargos.map((cargo, index) => {
                const isSelected = cargo.descr_en === selectedCargo;
                const numberOfInv = counts[cargo.ID] || 0;

                if (
                  cargo.descr_en === "Scholarship Holders" &&
                  bolseirosData.length < 5
                ) {
                  return null;
                }
                if (
                  cargo.descr_en === "Laboratory Support" &&
                  labSupportData.length === 0
                ) {
                  return null;
                }

                return (
                  <Button
                    className={`mybutton ${isSelected ? "selected" : ""}`}
                    size="lg"
                    style={{ margin: "20px" }}
                    key={index}
                    value={cargo.descr_en}
                    onClick={handlePosts}
                  >
                    {cargo.descr_en} ({numberOfInv})
                  </Button>
                );
              })}
            </div>
          </div>

          <div
            className="container"
            style={{ minHeight: "312px", padding: "10px" }}
          >
            {isBolseiro ? (
              <PostsScholarship posts={posts} />
            ) : (
              <Posts posts={posts} />
            )}
          </div>
        </div>
      </section>
      {/* <!-- end team area --> */}
    </div>
  );
};

export default TeamExecutiveBoard;

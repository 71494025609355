import axios from 'axios';

const API_BASE_URL = window.config.DEV_LINK;
const ui_id = window.config.id

export const fetchPosts = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/investigador/getByUi/${ui_id}`);
        return response.data.payload;
    } catch (error) {
        console.error('Error fetching posts:', error);
    }
};
import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

export const ProjectPosts = ({ frames, loading }) => {
  let valorApoio = 0;
  let valorProjeto = 0;
  Moment.locale("pt");

  if (loading) {
    return (
      <ul className="item justify-content-center">
        <h3 style={{ marginTop: "300px", height: "100%" }}>Loading...</h3>
      </ul>
    );
  }

  let toEUR = new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <div>
      {frames.map((post) => {
        valorApoio =
          post.ipvc_budget.length !== 0 ? toEUR.format(post.ipvc_budget) : "";
        valorProjeto =
          post.total_budget.length !== 0 ? toEUR.format(post.total_budget) : "";
        let formatedStartDate = new Date(post.start_date).toLocaleDateString(
          "pt-pt"
        );
        let formatedEndDate = new Date(post.end_date).toLocaleDateString(
          "pt-pt"
        );

        return (
          <div className="container-fluid" key={post.id}>
            <div className="row">
              <div className="col-12">
                <div className="cardPubs" style={{ display: "flex" }}>
                  <div className="col-xl-9 col-lg-9">
                    <div className="cardPubs-body">
                      <div className="cardPubs-title">
                        <Link
                          to={{
                            pathname: "/project-details/" + post.id,
                            state: { projectID: post.id },
                          }}
                        >
                          <span style={{ fontSize: "20px" }}>
                            <b>{post.description}</b>
                          </span>
                        </Link>
                      </div>
                      <div className="cardPubs-text">
                        {post.summary === "" ? (
                          <div></div>
                        ) : (
                          <div>
                            <br></br>
                            <span>{post.summary}</span>
                            <br></br>
                            <br></br>
                          </div>
                        )}
                        <label style={{ fontSize: "15px", color: "#53565At" }}>
                          <b>Start Date:</b>
                        </label>
                        <span> {formatedStartDate}</span>
                        <label
                          style={{
                            fontSize: "15px",
                            color: "#53565At",
                            marginLeft: "10px",
                          }}
                        >
                          <b>End Date:</b>
                        </label>
                        <span> {formatedEndDate}</span>
                        <br></br>
                        {valorApoio && (
                          <>
                            <label
                              style={{ fontSize: "15px", color: "#53565At" }}
                            >
                              <b>IPVC Budget:</b>
                            </label>
                            <span> {valorApoio}</span>
                          </>
                        )}
                        {valorProjeto ||
                          (valorProjeto != "" && (
                            <>
                              <label
                                style={{
                                  fontSize: "15px",
                                  color: "#53565At",
                                  marginLeft: "10px",
                                }}
                              >
                                <b>Total Budget:</b>
                              </label>
                              <span> {valorProjeto}</span>
                            </>
                          ))}
                        <a href={post.url}></a>
                        <br></br>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectPosts;
